import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import HeaderPortal from './header-portal';
import Footer from './footer';
import CustomFetch from './fetch';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/main.scss';

export default function LayoutPortal({ children }) {
    const [display, setDisplay] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('ofpPortalToken')) {
            CustomFetch(
                `${process.env.GATSBY_API_URL}/getProfile`,
                'POST',
                {},
                function (result) {
                    setDisplay(true);

                    if (result.error === '') {
                    } else {
                        alert(String(result.error));
                    }
                },
                function (error) {
                    if (
                        String(error) === 'Unauthenticated.' ||
                        String(error) === 'CSRF token mismatch.'
                    ) {
                        localStorage.removeItem('ofpUserProfile');
                        localStorage.removeItem('ofpPortalToken');
                        navigate('/portal');
                    }
                }
            );
        } else {
            navigate('/portal');
        }
    }, []);

    return (
        <div id="app">
            {display === true ? (
                <>
                    <HeaderPortal />
                    {children}
                    <Footer />
                </>
            ) : null}
            <ToastContainer
                position="bottom-center"
                style={{ width: '600px' }}
            />
        </div>
    );
}
