import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { DownloadIcon } from '@primer/octicons-react';

import LayoutPortal from '../../components/layout-portal';
import Seo from '../../components/seo';
import CustomFetch from '../../components/fetch';
import CustomAlert from '../../components/alert';

export default function DocumentPage({ location }) {
    const [document, setDocument] = useState({});
    const [alertState, setAlertState] = useState({
        alertClass: 'dialog success',
        alertId: 'dialogWrap',
        alertText: '',
        show: false,
    });

    const alertStateChange = (data) => {
        setAlertState({
            show: data.show,
            alertText: data.alertText,
            alertClass: data.alertClass,
        });
    };

    const handleChange = (e) => {
        if (e) {
            setDocument((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));
        }
    };

    const handleDownload = (id) => {
        CustomFetch(
            `${process.env.GATSBY_API_URL}/documents/download/${id}`,
            'POST',
            {},
            function (result) {
                if (result.url !== '') {
                    window.open(result.url, '_blank');
                }
            },
            function (error) {
                alertStateChange({
                    show: true,
                    alertClass: 'dialog error',
                    alertText: String(error),
                });
            }
        );
    };

    const handleUpdate = (e) => {
        if (e) {
            e.preventDefault();

            CustomFetch(
                `${process.env.GATSBY_API_URL}/documents/${document.id}`,
                'PUT',
                document,
                function (result) {
                    if (result.error === '') {
                        navigate('/portal/documents', {
                            state: {
                                alert: true,
                                alertType: 'success',
                                alertMessage:
                                    'The document has been updated, Throughlife staff will contact you if required.',
                            },
                        });
                    } else {
                        alertStateChange({
                            show: true,
                            alertClass: 'dialog error',
                            alertText: String(result.error),
                        });
                    }
                },
                function (error) {
                    alertStateChange({
                        show: true,
                        alertClass: 'dialog error',
                        alertText: String(error),
                    });
                }
            );
        }
    };

    useEffect(() => {
        if (
            location !== null &&
            location.state !== null &&
            location.state.document_id
        ) {
            CustomFetch(
                `${process.env.GATSBY_API_URL}/documents/${location.state.document_id}`,
                'GET',
                {},
                function (result) {
                    setDocument(result);
                },
                function (error) {
                    alertStateChange({
                        show: true,
                        alertClass: 'dialog error',
                        alertText: String(error),
                    });
                }
            );
        } else {
            navigate('/portal/documents');
        }
    }, []);

    return (
        <LayoutPortal>
            <Seo title="Document" description="Client Portal - Document" />
            <div className="container container--portal">
                <div className="grid">
                    <div className="col-lg-12">
                        <div className="pitem">
                            <div className="plist">
                                <div className="plist__header plist__header--alt">
                                    {document ? document.label : ''}
                                </div>
                                {/* <div className="plist__doc">
                                    <p>To Whom It May Concern,</p>
                                    <p>
                                        <strong>[fund_list]</strong>
                                    </p>
                                    <p>I give my authority for</p>
                                    <ul>
                                        <li>
                                            <strong>
                                                Mr. Doug Mateljan, Authorised
                                                Representative Number 00000
                                            </strong>
                                        </li>
                                        <li>
                                            <strong>
                                                Mr. Steven Mateljan, Authorised
                                                Representative Number 00000
                                            </strong>
                                        </li>
                                        <li>
                                            <strong>
                                                and all staff of Throughlife
                                                Financial Solutions Pty Ltd
                                            </strong>
                                        </li>
                                    </ul>
                                    <p>
                                        <strong>
                                            PO Box 702
                                            <br />
                                            Morley, Western Australia, 6943
                                            <br />
                                            Ph: 08 9375 1149 Fax: 08 9276 9610
                                            <br />
                                            Email: admin@throughlife.com.au
                                        </strong>
                                    </p>
                                    <p>
                                        To be given access (by phone, fax, email
                                        or post to the above contact details)
                                        all information, documents,
                                        illustrations, request
                                        rollover/withdrawal documents required
                                        relating to my policies / accounts /
                                        fund membership / investments /
                                        mortgages / loans etc.
                                    </p>
                                    <p>
                                        This authority is effective immediately
                                        and until further notice.
                                    </p>
                                    <p>Kind regards,</p>
                                </div> */}
                                <div className="plist__sign">
                                    <form onSubmit={handleUpdate}>
                                        <div className="pformitem">
                                            <label htmlFor="status">
                                                Status
                                            </label>
                                            <select
                                                name="status"
                                                value={
                                                    document
                                                        ? document.status
                                                        : 0
                                                }
                                                onChange={handleChange}
                                            >
                                                <option value="0">
                                                    Pending
                                                </option>
                                                <option value="1">
                                                    Accepted
                                                </option>
                                                <option value="2">
                                                    Declined
                                                </option>
                                            </select>
                                        </div>
                                        <div className="pformitem">
                                            <label htmlFor="document">
                                                Document
                                            </label>
                                            <button
                                                className="downloadbtn btn"
                                                onClick={(e) => {
                                                    if (e) {
                                                        e.preventDefault();
                                                        handleDownload(
                                                            document.id
                                                        );
                                                    }
                                                }}
                                            >
                                                <DownloadIcon size={16} />{' '}
                                                Download
                                            </button>
                                        </div>
                                        <div className="pformitem">
                                            <label htmlFor="comment">
                                                Comment
                                            </label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                name="comment"
                                                value={
                                                    document
                                                        ? document.comment
                                                        : ''
                                                }
                                                onChange={handleChange}
                                            ></input>
                                        </div>
                                        <div className="pformbtn">
                                            <button type="submit">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomAlert alertState={alertState} />
        </LayoutPortal>
    );
}
