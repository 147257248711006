import React from 'react';
import { Link } from 'gatsby';
import { PersonIcon, SignOutIcon } from '@primer/octicons-react';

export default function NavigationPortal(props) {
    const { handleLogout } = props;

    return (
        <nav className="app-menu-portal">
            <ul>
                <li>
                    <Link to="/portal/dashboard" activeClassName="active">
                        Dashboard
                    </Link>
                </li>
                {/* <li>
                    <Link to="/portal/documents" activeClassName="active">
                        Documents
                    </Link>
                </li> */}
                <li>
                    <Link to="/portal/archives" activeClassName="active">
                        Archives
                    </Link>
                </li>
                <li>
                    <Link to="/portal/profile" activeClassName="active">
                        <PersonIcon size={16} /> Profile
                    </Link>
                </li>
                <li>
                    <button onClick={handleLogout}>
                        <SignOutIcon size={16} /> Logout
                    </button>
                </li>
            </ul>
        </nav>
    );
}
