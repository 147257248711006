import React from 'react';
import { Link, navigate } from 'gatsby';
import Image from './image';
import { useMediaQuery } from 'react-responsive';
import NavigationPortal from './navigation-portal';
import MobilenavPortal from './mobilenav-portal';
import CustomFetch from './fetch';

const DesktopPortal = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    return isDesktop ? children : null;
};

const MobilePortal = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1200 });
    return isMobile ? children : null;
};

export default function HeaderPortal() {
    const handleLogout = (e) => {
        if (e) {
            e.preventDefault();

            CustomFetch(
                `${process.env.GATSBY_API_URL}/logout`,
                'POST',
                {},
                function (result) {
                    if (result.error === '') {
                        localStorage.removeItem('ofpPortalToken');
                        localStorage.removeItem('ofpUserProfile');

                        navigate('/portal');
                    } else {
                        alert(String(result.error));
                    }
                },
                function (error) {
                    alert(String(error));
                }
            );
        }
    };

    return (
        <header className="app-header p-header">
            <div className="container p-container">
                <div className="grid">
                    <div className="col-lg-4">
                        <div className="logo-portal">
                            <Link to="/portal/dashboard">
                                <Image
                                    src="throughlife-logo.svg"
                                    alt="Throughlife Financial Solutions"
                                />
                            </Link>
                            <span>Client Portal</span>
                        </div>
                    </div>
                    <DesktopPortal>
                        <div className="col-lg-8">
                            <NavigationPortal handleLogout={handleLogout} />
                        </div>
                    </DesktopPortal>
                    <MobilePortal>
                        <MobilenavPortal />
                    </MobilePortal>
                </div>
            </div>
        </header>
    );
}
