import React, { useState } from 'react';
import { Link } from 'gatsby';
import { PersonIcon, SignOutIcon } from '@primer/octicons-react';

export default function MobilenavPortal() {
    const [isOpen, setOpen] = useState('false');

    const menuToggle = () => {
        setOpen(!isOpen);
    };

    return (
        <div>
            <button
                className={isOpen ? 'menu-toggle' : 'menu-toggle open'}
                onClick={menuToggle}
            >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </button>
            <nav className={isOpen ? 'app-mobmenu' : 'app-mobmenu open'}>
                <ul>
                    <li>
                        <Link to="/portal/dashboard" activeClassName="active">
                            Dashboard
                        </Link>
                    </li>
                    {/* <li>
                        <Link to="/portal/documents" activeClassName="active">
                            Documents
                        </Link>
                    </li>
                    <li>
                        <Link to="/portal/archives" activeClassName="active">
                            Archives
                        </Link>
                    </li> */}
                    <li>
                        <Link to="/portal/profile" activeClassName="active">
                            <PersonIcon size={16} />
                            Profile
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact">
                            <SignOutIcon size={16} />
                            Logout
                        </Link>
                    </li>
                </ul>
            </nav>
            <div className={isOpen ? 'underlay' : 'underlay open'}></div>
        </div>
    );
}
